import axios from "axios"
import environment from "../environments/environment"

export class EntityService {

  constructor() { }

  getDatas(entityName: string){
    return window.fetch(environment.apiUrl+entityName)
  }
  getDataById(entityName: string, id: string){
    return window.fetch(environment.apiUrl+entityName+"/"+id)
  }
  getDataBySlug(entityName: string, slug: string){
    return window.fetch(environment.apiUrl+entityName+"/by/slug/"+slug)
  }
  getDatasByPage(entityName: string, pageNumber:Number = 1, pageLimit:Number =5){
    return window.fetch(environment.apiUrl+entityName+"/by/page?pageNumber="+pageNumber+"&pageLimit="+pageLimit)
  }
  searchDataByPage(entityName: string,query:string, pageNumber:Number = 1, pageLimit:Number =5){
    return window.fetch(environment.apiUrl+entityName+"/search?"+query+"&pageNumber="+pageNumber+"&pageLimit="+pageLimit)
  }
  
  addData(entityName: string, data: any){
    return axios.post(environment.apiUrl+entityName, data)
  }
  
  updateData(entityName: string, entityId: string, data: any){
    return axios.put(environment.apiUrl+entityName+"/"+entityId, data)
  }

  deleteData(entityName: string, entityId: string){
    return axios.delete(environment.apiUrl+entityName+"/"+entityId)
  }


}
