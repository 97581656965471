import React, { FC, useEffect, useState } from 'react';
import './Footer.css';
import { EntityService } from '../../services/entity.service';
import { Page } from '../../models/page';
import { Link } from 'react-router-dom';

interface FooterProps {
    website: any
}

const Footer: FC<FooterProps> = ({ website }) => {
    const entityService = new EntityService()
    const [pages, setPages] = useState<Page[]>([])
    const [value, setValue] = useState('');

    useEffect(() => {
        entityService.getDatas("page")
            .then(
                (axiosResult: any) => {
                    return axiosResult.json()
                })
            .then(data => {
                console.log(data);
                if (data.isSuccess) {
                    setPages(data.results)
                }
            })
    }, [value])
    return (
        <div className="Footer">
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 pr-30">
                            <div className="footer_about">
                                {/* <a className="navbar-brand logo-sticky  d-flex justify-content-center">
                            <img className="img-fluid" src={website?.logo} alt={website?.name} />
                            </a> */}
                                <h3>{website?.name}</h3>
                                <div className="line-title-left"></div>
                                <p className="text-white">
                                    {website?.description}
                                </p>
                            </div>
                            <div className="footer_subscribe">
                                {/* <h5>subcribe now</h5>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Enter your email here">
                                <span className="input-group-btn">
                                    <button type="submit"><i className="fa fa-paper-plane"></i></button>
                                </span>
                            </div> */}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer_address">
                                <h3>Mentions légales</h3>
                                <div className="line-title-left"></div>
                                <ul className="address-list">
                                    {
                                        pages.map((page) => {
                                            return <li key={page._id}>
                                                <Link to={"page/" + page.slug}>{page.name}</Link>
                                            </li>
                                        })
                                    }

                                </ul>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer_address">
                                <h3>Maintenance</h3>
                                <div className="line-title-left"></div>
                                <ul className="address-list">
                                    <li >
                                        <Link to={"/maintenance"}>Nos Formules</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="footer_address">
                                <h3>adresse</h3>
                                <div className="line-title-left"></div>
                                <ul className="address-list">
                                    <li>
                                        <p><i className="fas fa-map-marker"></i>
                                            {` ${website?.street}  ${website?.code_postal} ${website?.city} `}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <i className="fas fa-mobile-alt"></i>
                                            <a href={'tel:' + website?.phone}>{website?.phone} </a>

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <i className="far fa-envelope"></i>
                                            <a href={'mailto:' + website?.email}>{website?.email}</a></p>
                                    </li>
                                </ul>
                                {/* <ul className="footer_social list-inline">
                                <li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-vimeo-v"></i></a></li>
                            </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <a href="#">{website?.name}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
    ;

export default Footer;
