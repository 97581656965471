import React, { Fragment, useEffect, useState } from 'react';
import { Route, Link, BrowserRouter, Routes } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Services from './components/Services/Services';
import HomePage from './components/HomePage/HomePage';
import { EntityService } from './services/entity.service';
import PageContainer from './components/PageContainer/PageContainer';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Maintenance from './components/Maintenance/Maintenance';
const { NODE_ENV } = process.env

function App() {
  const entityService = new EntityService()
  const isLoading = true


  const [website, setWebsite] = useState<any>(null)
  const [value, setValue] = useState('');

  if (NODE_ENV !== "development") {
    window.console.log = () => { }
  }

  useEffect(() => {
    entityService.getDatas("shop_params")
      .then(
        (axiosResult: any) => {
          return axiosResult.json()
        })
      .then(data => {
        console.log(data);
        if (data.isSuccess) {
          setWebsite(data.results[0])
        }
      })
  }, [value])


  return (
    <BrowserRouter>
      <Header website={website}></Header>

      <Routes>
        <Route path='/' element={<HomePage website={website} />} />

        <Route path='/page/:slug' element={<PageContainer website={website} />} />


        <Route path='/maintenance' element={<Maintenance website={website} />} />


        <Route path='/*' element={<ErrorPage website={website} />} />


      </Routes>

      <Footer website={website}></Footer>
    </BrowserRouter>
  );
}

export default App;
