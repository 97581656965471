import React, { FC, useEffect, useState } from 'react';
import './Maintenance.css';
import { EntityService } from '../../services/entity.service';
import { MaintenanceModel } from '../../models/maintenanceModel';
import { formatePrice } from '../../services/utils';
import PaypalPlan from '../PaypalPlan/PaypalPlan';

interface MaintenanceProps {
  website: any
}

const Maintenance: FC<MaintenanceProps> = () => {
  const entityService = new EntityService()
  const [formules, setFormules] = useState<MaintenanceModel[]>([])
  const [value, setValue] = useState('');

  useEffect(() => {
    window.scrollTo(0,0)
    entityService.getDatas("maintenance")
      .then(
        (response: any) => {
          return response.json()
        })
      .then(data => {
        console.log(data);
        if (data.isSuccess) {
          setFormules(data.results)
        }
      })
  }, [value])
  return (
    <div className="Maintenance">
      <section className="header-section" >
        <div className="container">
          <h1 className="section-heading1">Nos formules de maintenance</h1>
        </div>
      </section>

      <div className="maintenance-formule container p-3">
        <div className="row gx-1">
          {
            formules.map((formule) => {

              return <div key={formule._id} className="col-md-4 p-2">
                <div className="maintenance-formule-item border shadow p-3">
                  <h2 className='text-center py-2 border shadow'>
                    {formule.name}
                  </h2>

                  <div className="price d-flex column p-2 align-items-center justify-content-center">
                    <div className="solde_price p-1">
                      {formatePrice(formule.solde_price)}/mois
                    </div>
                    <del>

                      <div className="regular_price p-1">
                        {formatePrice(formule.regular_price)}/mois
                      </div>
                    </del>
                  </div>

                  {
                    formule.options.map((option) => {
                      return <div key={option._id} className='text-center bold  py-2'>
                        <h3 className='p-2 shadow'>{option.name}</h3>
                        <ul>
                          {
                            option.values.map((value: any) => {
                              return <li key={value._id}>
                                {value.name}
                              </li>
                            })
                          }

                        </ul>
                      </div>
                    })
                  }
                  <div className="p-2 d-flex">
                    <PaypalPlan
                      panId={formule.plan_id}
                      id={formule._id}
                    />

                  </div>

                </div>
              </div>
            })
          }
        </div>
      </div>
      <div className="container action-btn d-none d-md-block">
        <div className="row gx-1">
          {
            formules.map((formule) => {

              return <div key={formule._id} className="col-md-4 p-2">
                <div className="maintenance-formule-item border p-3">

                  <div className="d-flex p-2">
                    <PaypalPlan
                      panId={formule.plan_id}
                      id={formule._id}
                    />

                  </div>

                </div>
              </div>
            })
          }
        </div>

      </div>
    </div>
  )
};

export default Maintenance;
