import React, { FC, useEffect, useState } from 'react';
import './Header.css';
import { EntityService } from '../../services/entity.service';
import { getMetaItem } from '../../services/utils';
import Slider from '../Slider/Slider';
import { Link } from 'react-router-dom';

interface HeaderProps {
  website: any
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {

  const { website } = props

  return (
    <div className="Header">
      <div className="top_navbar d-none d-lg-block">
        <nav className="navbar">
          <div className="container">
            {
              website?.street ?
                <span className="color-yellow d-none d-lg-block fa fa-map-marker">
                  <Link className="text-white" to="/">
                    {` ${website?.street}  ${website?.code_postal} ${website?.city} `}
                  </Link>
                </span>
                :

                null

            }
            {
              website?.phone ?
                <span className="color-yellow d-none d-lg-block fa fa-clock">
                  <a className="text-white" href={'tel:' + website?.phone}>{website?.phone} </a>
                </span>
                :
                null
            }
            {
              website?.available ?
                <span className="color-yellow d-none d-lg-block fa fa-clock ml-auto">
                  <a className="text-white" href="#">
                    {website?.available}
                  </a>
                </span>
                :
                null
            }
          </div>
        </nav>
      </div>

      <header className="main-header shadow">
        <div className="main_navbar">

          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
              <a className="navbar-brand logo-sticky">
                <img className="img-fluid" src={website?.logo} alt={website?.name} /></a>
              <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarNav"><span
                className="navbar-toggler-icon"></span></button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto">

                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      Accueil
                    </Link>
                  </li>

                  {/* <li className="nav-item ">
                                    <a href="#" className="nav-link">
                                        Services
                                    </a>
                                </li>

                                <li className="nav-item ">
                                    <a href="#" className="nav-link">
                                        Projets
                                    </a>
                                </li>

                                <li className="nav-item ">
                                    <a href="#" className="nav-link">
                                        Contact
                                    </a>
                                </li> */}

                </ul>

                <ul className="navbar-nav ml-auto">
                  <li className="nav-item mt-4 mb-4">
                    {/* <a className="link-outline" href="/assets/login.html">
                    <i className="icon fas fa-sign-in-alt"></i>
                    &nbsp;Login
                  </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header >
    </div >
  )
};

export default Header;
