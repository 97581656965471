import React, { FC, useEffect, useState } from 'react';
import './Slider.css';
import { EntityService } from '../../services/entity.service';
import { Slide } from '../../models/slide';

interface SliderProps { }

const Slider: FC<SliderProps> = () => {

  const entityService = new EntityService()
  const [slides, setSlides] = useState<Slide[]>([])
  const [value, setValue] = useState('');

  useEffect(() => {
    entityService.getDatas("slide")
      .then(
        (axiosResult: any) => {
          return axiosResult.json()
        })
      .then(data => {
        console.log(data);
        if (data.isSuccess) {
          setSlides(data.results)
        }
      })
  }, [value])

  return (
    <div className="Slider">
      <div id="slider">
        <div className="container-fluid">
          <div className="row">

            <div id="fw_al_002" className="carousel slide swipe_x ps_easeInOutSine" data-ride="carousel"
              data-pause="hover" data-interval="5000" data-duration="1000">


              <div className="carousel-inner" role="listbox">

                {
                  slides.map((slide, index) => {
                    return <div key={slide?._id} className={index == 0 ? "carousel-item active" : "carousel-item"}
                      style={{ transitionDuration: index == 0 ? "1000ms" : "" }}>
                      <div className="overl"></div>

                      <img src={slide?.imageUrl} alt={slide?.title} />


                      <div className="fw_al_002_slide">
                        <h1 className="bold animated fadeInRight" data-animation="animated fadeInRight">
                          {slide?.title}
                        </h1>
                        <p data-animation="animated fadeInRight" className="animated fadeInRight">
                          {slide?.description}
                        </p>
                        {/* <button type="submit" className="btns btn-primary">Learn More</button>
                  <button type="submit" className="btns btn-outline-info blue bg-warning ml-3">Contact
                    Us</button> */}
                      </div>
                    </div>

                  })
                }






              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Slider;
