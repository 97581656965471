import React, { FC, useEffect, useState } from 'react';
import './PageContainer.css';
import { useLocation, useParams } from 'react-router-dom';
import { EntityService } from '../../services/entity.service';
import { Page } from '../../models/page';

interface PageContainerProps {
  website: any
}

const PageContainer: FC<PageContainerProps> = () => {


  const { slug } = useParams();

  const entityService = new EntityService()
  const [page, setPage] = useState<Page>()
  const [value, setValue] = useState('');

  useEffect(() => {
    if (slug) {
      window.scrollTo(0,0)
      entityService.getDataBySlug("page", slug)
      .then(
        (axiosResult: any) => {
          return axiosResult.json()
        })
      .then(data => {
        console.log(data);
        if (data.isSuccess) {
          setPage(data.result)
        }
      })
    }
  }, [slug])




  return (
    <div className="PageContainer">
      <section className="header-section" >
        <div className="container">
          <h1 className="section-heading1">{page?.name}</h1>
        </div>
      </section>
      <div className="PageContainer_content py-3">
        <div className="container" dangerouslySetInnerHTML={{__html: page?.content || ""}}>
        
        </div>
      </div>
    </div>
  )
};

export default PageContainer;
