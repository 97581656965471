import React, { FC } from 'react';
import './HomePage.css';
import Service from '../Service/Service';
import Slider from '../Slider/Slider';

interface HomePageProps {
    website: any
}

const HomePage: FC<HomePageProps> = ({website}) => (
  <div className="HomePage">
         <Slider/>
        <Service/>

        <section className="offer-section" id="special">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10 col-md-10">
                        <div className="offer-content">
                            <h1 className="section-heading1">Notre service client est à votre écoute.</h1>
                            <h4 className="offer">Disponible 24/7</h4>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 col-md-2 col-xs-12">
                        <a href="#" className="btn btn-outline-info blue bg-warning">
                        { website?.phone }
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section id="about" className="bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 col-sm-12">
                        <div className="about_img mb-30-sm shadow">
                            <img src="/assets/images/bg-img/about.jpg" alt="about_img"/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-sm-12">
                        <div className="about">
                            <h1 className="section-heading">
                              Confiez votre projet à des professionnels réputés.
                            </h1>
                            <p>
                            Nos solutions informatiques sont adaptées pour chacun de 
                            nos clients selon ses besoins et ses objectifs. Nous accompagnons nos clients 
                            au quotidien et adaptons son infrastructure informatique et son système 
                            d’information à son développement et sa stratégie.

Nos services informatiques managés (infogérance, sécurité, infrastructures, hébergement et transformation 
digitale) permettent à notre équipe d’experts de piloter efficacement le système informatique de nos clients avec qualité et réactivité.
                            </p>
                            <ul className="list-item">
                                <li><i className="far fa-check-square"></i> Réactivité</li>
                                <li><i className="far fa-check-square"></i> Efficacité </li>
                                <li><i className="far fa-check-square"></i> Bon rapport qualité prix</li>
                            </ul>
                            {/* <button className="btn btn-primary" type="button">Download Now</button> */}
                        </div>
                    </div>

                </div>
            </div>
        </section>
  </div>
);

export default HomePage;
