import React, { FC, useEffect, useState } from 'react';
import './Service.css';
import { EntityService } from '../../services/entity.service';
import { ServiceModel } from '../../models/service';

interface ServiceProps { }

const Service: FC<ServiceProps> = () => {

  const entityService = new EntityService()
  const [services, setServices] = useState<ServiceModel[]>([])
  const [value, setValue] = useState('');

  useEffect(() => {
    entityService.getDatas("service")
      .then(
        (response: any) => {
          return response.json()
        })
      .then(data => {
        console.log(data);
        if (data.isSuccess) {
          setServices(data.results)
        }
      })
  }, [value])

  return (
    <section id="things_you_get">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h1 className="">Nos services</h1>
              {/* <p className="">Nous intervenons dans 4 domaines d'activités.</p> */}
            </div>
          </div>
        </div>
        <div className="row">
          {
            services.map((service) => {
              return <div key={service?._id} className="col-md-6 col-sm-6">
                <div className="my-col p-4 shadow">
                  <div className="mb-3 section-icon fa-2x">
                    <i className={service?.icon}></i>
                  </div>
                  <div className="section-heading mt-2">
                    <h4 className="text-capitalize">{service?.title}</h4>
                  </div>
                  <div className="seaction-paragraph">
                    <p className="text-center">
                      {service?.description}
                    </p>
                  </div>
                </div>
              </div>
            })
          }


        </div>
      </div>
    </section>
  )
}

export default Service;
