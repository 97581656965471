import React, { FC, useEffect, useState } from 'react';
import './PaypalPlan.css';

interface PaypalPlanProps {
  panId: string
  id?: string
}

const PaypalPlan: FC<PaypalPlanProps> = ({ panId, id }) => {

  const [pay, setPay] = useState<boolean>(false);
  useEffect(() => {
    const paypal = (window as any).paypal
    const elt = document.querySelector('#paypal-button-container-' + id)
    if (pay && id && !elt?.innerHTML) {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function (data: any, actions: any) {
          return actions.subscription.create({
            /* Creates the subscription */
            plan_id: panId
          });
        },
        onApprove: function (data: any, actions: any) {
          alert(data.subscriptionID); // You can add optional success message for the subscriber here
        }
      }).render('#paypal-button-container-' + id); // Renders the PayPal button

    }

  }, [pay])

  return (
    <>
      {
        pay ?
          <div id={'paypal-button-container-' + id} className='flex-1' >

          </div>
          :
          <button className='btn flex-1' onClick={()=>setPay(true)} >
            S'abonner
          </button>
    }
    </>

)
};

export default PaypalPlan;
