import React, { FC } from 'react';
import './ErrorPage.css';
import { Link } from 'react-router-dom';

interface ErrorPageProps {
  website: any
}

const ErrorPage: FC<ErrorPageProps> = () => (
  <div className="ErrorPage">

    <div className="error-container container">
      <h1 className="">404 Page non retrouvée</h1>

      <p>Désolé, nous n'avons pas retrouvé la page que vous recherchez</p>

      <Link to="/">
        <button className="btn">Retour à l'accueil</button>
      </Link>

    </div>
  </div>
);

export default ErrorPage;
